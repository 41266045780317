var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({staticClass:"environments-list elevation-0 overflow-hidden",attrs:{"items":_vm.environments,"headers":_vm.$static.headers,"search":_vm.search,"hide-default-footer":"","disable-pagination":"","disable-sort":""},on:{"click:row":_vm.goToEnv},scopedSlots:_vm._u([{key:"progress",fn:function(){return [(_vm.$attrs.loading)?_c('v-progress-linear',{staticClass:"environments-list__loader",attrs:{"indeterminate":"","color":"secondary"}}):_vm._e()]},proxy:true},{key:"top",fn:function(){return [_c('div',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:(['UpdateProject', _vm.projectCanonical]),expression:"['UpdateProject', projectCanonical]"}],staticClass:"environments-list__header elevation-0"},[_c('v-text-field',{staticClass:"cy-search-field ma-0 pa-0",attrs:{"placeholder":_vm.$t('forms.fieldSearch'),"hide-details":"","clearable":"","data-cy":"search-field","prepend-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('CyButton',{attrs:{"icon":"add","data-cy":"add-env-button","disabled":_vm._.isEmpty(_vm.configRepository),"to":{ name: 'addServiceEnv', params: { mode: 'add-env' } }}},[_vm._v(" "+_vm._s(_vm.$t('environment.create'))+" ")])],1)]},proxy:true},{key:"item.canonical",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex align-center"},[_c('CyAvatar',{attrs:{"item":item,"sm":""}}),_c('span',{staticClass:"ml-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.canonical)+" ")])],1)]}},{key:"item.provider",fn:function(ref){
var item = ref.item;
return [(item.cloudProviderCanonical)?_c('div',{staticClass:"d-flex"},[_c('CyIconCredential',{staticClass:"mr-1",attrs:{"type":item.cloudProviderCanonical,"size":"24"}}),_c('span',[_vm._v(" "+_vm._s(item.cloudProviderName)+" ")])],1):_vm._e()]}},{key:"item.useCase",fn:function(ref){
var item = ref.item;
return [(item.useCase)?_c('v-icon',{staticClass:"usecase__icon ml-2",attrs:{"size":"15"}},[_vm._v(" fa-cube ")]):_vm._e(),(item.useCase)?_c('span',{staticClass:"usecase__text ml-1"},[_vm._v(" "+_vm._s(item.useCase)+" ")]):_vm._e()]}},{key:"item.pipelineStatus",fn:function(ref){
var item = ref.item;
return [(item.pipelineStatus)?_c('div',{staticClass:"pipeline-status d-flex align-center"},[_c('v-icon',{class:['pipeline-status__icon', ((item.pipelineStatus) + "-color")]},[_vm._v(" "+_vm._s(_vm.pipelineIcon(item.pipelineStatus))+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm._.capitalize(item.pipelineStatus))+" ")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end space-x-2"},[(item.startStop)?[_c('CyTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CyButton',_vm._g({directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:(['CreateBuild', _vm.projectCanonical]),expression:"['CreateBuild', projectCanonical]"}],attrs:{"loading":item.startStop.starting,"data-cy":"play-button","icon":"play_arrow","icon-only":"","sm":"","theme":"primary","variant":"tertiary","light":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('start', item)}}},on))]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('startStopTooltipText', { action: _vm.$t('pipeline.start') }))+" ")]),_c('CyTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CyButton',_vm._g({directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:(['CreateBuild', _vm.projectCanonical]),expression:"['CreateBuild', projectCanonical]"}],attrs:{"loading":item.startStop.stopping,"data-cy":"stop-button","icon":"stop","icon-only":"","sm":"","theme":"primary","variant":"tertiary","light":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('stop', item)}}},on))]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('startStopTooltipText', { action: _vm.$t('pipeline.stop') }))+" ")])]:_vm._e(),(!_vm._.isEmpty(_vm.getEnvActions(item)))?_c('CyMenu',{attrs:{"data-cy":"actions-menu","offset-y":"","left":"","sm":"","min-width":"190","max-width":"300"}},[_c('v-list',[_c('v-subheader',[_c('div',{staticClass:"v-subheader__title cy-headline"},[_vm._v(" "+_vm._s(_vm.$t('Environment'))+" ")]),_c('div',{staticClass:"v-subheader__environment"},[_c('CyAvatar',{attrs:{"item":item,"sm":""}}),_c('span',{staticClass:"canonical"},[_vm._v(" "+_vm._s(item.canonical)+" ")])],1)]),_vm._l((_vm.getEnvActions(item)),function(ref,index){
var action = ref.action;
var label = ref.label;
var icon = ref.icon;
var color = ref.color;
var divider = ref.divider;
var disabled = ref.disabled;
var readOnly = ref.readOnly;
return [(divider)?_c('v-divider',{key:("menu-divider-" + index)}):_c('v-list-item',{directives:[{name:"hover-siblings",rawName:"v-hover-siblings"}],key:("menu-item-" + index),attrs:{"disabled":disabled},on:{"click":action}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center",style:({ color: color })},[_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":disabled,"color":color || 'primary',"size":"24"}},[_vm._v(" "+_vm._s(icon)+" ")]),_c('span',{class:{ 'red--text': color === 'error' }},[_vm._v(" "+_vm._s(label)+" ")]),(readOnly)?_c('span',[_c('CyTooltip',{attrs:{"max-width":240,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('div',_vm._g({},onTooltip),[_c('CyTag',{staticClass:"action-item__readonly-chip ml-6 mr-1",attrs:{"variant":"default","small":""}},[_vm._v(" "+_vm._s(_vm.$t('readOnly'))+" ")])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.$t('readOnlyTooltipText')))}})])],1):_vm._e()],1)],1)],1)]})],2)],1):_vm._e()],2)]}}],null,true)},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }